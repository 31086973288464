import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { array, func, string } from 'prop-types';
import Modal from 'react-modal';
import PlaySmmmall from '../../../../../../assets/images/thumbnail.png';
import Tick from '../../../../../../assets/icons/tick.svg';
import GifPlayer from 'react-gif-player';

import './concepts.scss';

const VideoMap = ({
  video,
  openCurrentVideo,
  currVideo,
  playCurrentVideo,
  playVideo,
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      border: 0,
      backgroundColor: 'transparent',
    },
    overlay: {
      backgroundColor: '#000000bf',
      zIndex: 999,
    },
  };

  return (
    <div
      className='tree video-tree video-tree-concept'
      style={{ marginLeft: '8rem', marginTop: '-3rem' }}
    >
      <div className='videos-tree'>
        <div className='side-line' />
        {video?.map((item, i) => (
          <div className='branch' key={item?._id}>
            <div className='branch-line' style={{ paddingLeft: 29 * (i + 1) }}>
              {' '}
            </div>
            <div className='video-card d-flex align-items-center'>
              <div
                className='type'
                onClick={() => {
                  playCurrentVideo(item?._id);
                  openCurrentVideo(item?._id);
                }}
                onKeyPress={() => {}}
                role='button'
                tabIndex={0}
                aria-label='Play'
              >
                <img
                  src={item?.thumbnailUrl ?? PlaySmmmall}
                  alt=''
                  style={{ width: '100px', height: '50px', objectFit: 'cover' }}
                />
                <Modal
                  isOpen={playVideo === item?._id}
                  onRequestClose={() => playCurrentVideo(null)}
                  contentLabel='My dialog'
                  style={customStyles}
                >
                  <div>
                    <div className='d-flex justify-content-center align-items-center'>
                      {item?.type == 'video' ? (
                        <Vimeo
                          video={item?.link}
                          autoplay
                          width='720px'
                          height='480px'
                        />
                      ) : item?.type == 'gif' ? (
                        <GifPlayer
                          gif={item?.link}
                          width='720px'
                          height='480px'
                        />
                      ) : item?.type == 'mindmap' ? (
                        <iframe
                          src={item?.link}
                          width={window.innerWidth + 'px'}
                          height={window.innerHeight + 'px'}
                        ></iframe>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Modal>
              </div>

              <a href='#'>
                <h4>{item?.title}</h4>
              </a>
              {currVideo === item?._id && <img src={Tick} alt='' />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

VideoMap.propTypes = {
  video: array.isRequired,
  openCurrentVideo: func.isRequired,
  currVideo: string.isRequired,
  playCurrentVideo: func.isRequired,
  playVideo: string.isRequired,
};

export default VideoMap;
