import React, { useState, useEffect } from 'react';
import './slider.scss';
import moment from 'moment';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../components/dashboard/dashboard.scss';
import { Button } from 'react-bootstrap';
import { bool, func, object, string } from 'prop-types';

import SubjectModel from './SubjectModel';
import { useHistory, useLocation } from 'react-router';

import { chevRight, chevLeft } from '../../../assets/icons/IconList';

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        background: 'white',
        color: 'black',
        alignItems: 'center',
        justifyContent: 'center',
        // border: 'solid 0.5px',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        left: '0px',
      }}
      onClick={onClick}
    >
      {chevLeft}
    </div>
  );
}
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        background: 'white',
        color: 'black',
        alignItems: 'center',
        justifyContent: 'center',
        // border: 'solid 0.5px',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        right: '-10px',
      }}
      onClick={onClick}
    >
      {chevRight}
    </div>
  );
}

const SimpleSlider = ({
  myClassesList,
  handleSubjectModel,
  handleModel,
  setScheduleId,
  showSubjectModel,
}) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrow: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [modalInfo, setModalInfo] = useState({});

  const handleDetailsModal = (myClass) => {
    handleSubjectModel(true);
    setModalInfo(myClass);
  };

  const openReschedule = (item) => {
    handleModel(true);
    setScheduleId(item?.id);
  };

  useEffect(() => {
    const addtopic = query.get('addtopic');
    const detailviews = query.get('detailviews');

    if (!detailviews && !addtopic) {
      handleSubjectModel(false);
      history.push('/teacher/classes');
    }
    // else if (braingym){
    //   setViewBrain('workout');
    // }
  }, [location.search]);

  return (
    <>
      <Slider {...settings}>
        {myClassesList?.map((item) => (
          <div key={item.id} className=' sliderBox'>
            <div className='sub-box-main row'>
              <div className='sub-box-left col-sm-8'>
                <h3 className='subname'>{item.subject}</h3>

                <p>
                  <span>{moment(item?.date).format('dddd, MMMM Do')}</span> |{' '}
                  
                  <span className='span-three'>{moment(item.startTime).format('h:mmA')}</span>
                  <span className='span-three'> - {moment(item.startTime).add(45, 'minutes').format('h:mmA')}</span>
                  
                </p>
                <span>
                    {' '}
                   
                    {`(${item.timezone})`}
                  </span>
                {/* <span className='span-three'>IST |{' '} {item.timeslot} </span> */}
                {/* <p className='span-three'>UST |{' '} 
                   <span className='span-three'>{moment(item.startTime).format('h:mmA')}</span>
                  <span className='span-three'> - {moment(item.startTime).add(45, 'minutes').format('h:mmA')}</span>
                </p> */}
              </div>
              <div className='sub-box-right col-sm-4'></div>
            </div>
            <div className='row gradesection'>
              <div className='col-sm-4 floatleft'>
                <p>Grade {item.grade}</p>
              </div>
              <div className='col-sm-4 floatleft'>
                <p>{item.numberOfStudent} students</p>
              </div>
              <div className='col-sm-4 floatleft'>
                <p>45 minutes</p>
              </div>
            </div>
            <div className='buttondiv'>
              <Button
                className='viewdetailsbutton'
                onClick={() => {
                  handleDetailsModal(item);
                  history.push({
                    pathname: '/teacher/classes',
                    search: 'detailviews=true',
                  });
                }}
              >
                VIEW DETAILS
              </Button>
            </div>
          </div>
        ))}
      </Slider>
      <SubjectModel
        modalInfo={modalInfo}
        handleSubjectModel={handleSubjectModel}
        showSubjectModel={showSubjectModel}
      />
    </>
  );
};
SimpleSlider.propTypes = {
  myClassesList: object.isRequired,
  handleSubjectModel: func.isRequired,
  showSubjectModel: bool.isRequired,
  handleModel: func.isRequired,
  setScheduleId: string.isRequired,
};
export default SimpleSlider;
