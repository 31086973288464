import moment from 'moment';
import config from './config';
import crypto from  'crypto-browserify';
import { Buffer } from 'buffer';

const REACT_APP_DECODE_AWS_KEY = "tautMore2024!@#$tautMore2025!@#$"

export const useQuery = (params) => {
  const data = {};
  params
    .substring(1)
    .split('&')
    .map((val) => {
      if (val) {
        const indi = val.split('=');
        data[indi[0]] = indi[1];
      }
    });
  return data;
};

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
export const currentDate = () => {
  const cDate = new Date();
  const mS = months[cDate.getMonth()];
  let daySuffix = '';
  switch (cDate.getDate()) {
    case 1:
    case 21:
    case 31:
      daySuffix = 'st';
      break;
    case 2:
    case 22:
      daySuffix = 'nd';
      break;
    case 3:
    case 23:
      daySuffix = 'rd';
      break;
    default:
      daySuffix = 'th';
      break;
  }

  return `${cDate.getDate()}${daySuffix} ${mS}`;
};
export const currentDateWithVal = (val) => {
  const cDate = new Date(val);
  const mS = months[cDate.getMonth()];
  const year = cDate.getFullYear();
  let daySuffix = '';
  switch (cDate.getDate()) {
    case 1:
    case 21:
    case 31:
      daySuffix = 'st';
      break;
    case 2:
    case 22:
      daySuffix = 'nd';
      break;
    case 3:
    case 23:
      daySuffix = 'rd';
      break;
    default:
      daySuffix = 'th';
      break;
  }

  return `${cDate.getDate()}${daySuffix} ${mS} ${year}`;
};
export const currentDateMon = (val) => {
  const cDate = new Date(val);
  const mS = months[cDate.getMonth()];
  let daySuffix = '';
  switch (cDate.getDate()) {
    case 1:
    case 21:
    case 31:
      daySuffix = 'st';
      break;
    case 2:
    case 22:
      daySuffix = 'nd';
      break;
    case 3:
    case 23:
      daySuffix = 'rd';
      break;
    default:
      daySuffix = 'th';
      break;
  }

  return `${cDate.getDate()}${daySuffix} ${mS} `;
};

export const formatDate = (dateval) => {
  const dateObj = new Date(dateval);
  dateObj.setUTCHours(0, 0, 0, 0);
  const month = months[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  const output = `${day} ${month} ${year} `;
  return output;
};
export const formatDateAllNumbers = (dateval) => {
  let objectDate = new Date(dateval);
  let day = objectDate.getUTCDate();
  let month = objectDate.getUTCMonth() + 1;
  let year = objectDate.getUTCFullYear();
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${day} - ${month} - ${year}`;
};

export const formatDateTime = (dateval) => {
  const dateObj = new Date(dateval);
  const month = months[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  const time = `${dateObj.getHours()}:${dateObj.getMinutes()}`;
  const output = `${day} ${month} ${year} - ${time} `;
  return output;
};
export const formatTime = (dateval) => {
  const dateObj = new Date(dateval);
  const time = `${dateObj.getHours()}:${dateObj.getMinutes()}`;
  const output = `${time} `;

  return output;
};

export const formatAMPM = (date) => {
  const dateObj = new Date(date);
  var hours = dateObj.getHours();
  var minutes = dateObj.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const formatTimeSectoMin = (timeval) => {
  const time = Number(timeval);

  const m = Math.floor(time % 3600);
  const mm = Math.floor(m / 60);
  const s = Math.floor((time % 3600) % 60);
  const ss = Math.floor(s % 60);

  const output = `${mm}:${ss} `;
  return output;
};

export const formatSecToMin = (timeval) => {
  const seconds = Number(timeval);
  const formate =
    seconds > 3600
      ? new Date(seconds * 1000).toISOString().substring(11, 16)
      : new Date(seconds * 1000).toISOString().substring(14, 19);

  return formate;
};

// below function is for calculate date differance in days.
export const dateDiffarance = (val1, val2) => {
  const date1 = new Date(val1);
  const date2 = new Date(val2);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return `${diffDays} days`;
};

export function isDateExpired(expirationDate) {
  const currentDate = new Date();
  const parsedExpirationDate = new Date(expirationDate);

  return parsedExpirationDate < currentDate;
}

export function isExpiringWithinThreeDays(expirationDate) {
  const currentDate = new Date();
  const parsedExpirationDate = new Date(expirationDate);

  const timeDifference = parsedExpirationDate - currentDate;
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  return daysDifference <= 3 && daysDifference >= 0;
}

export const arrayareEqual = (array1, array2) => {
  if (array1.length === array2.length) {
    return array1.every((element, index) => {
      if (element === array2[index]) {
        return true;
      }
      return false;
    });
  }
  return false;
};

export const daysRemaining = (givenDate) => {
  var eventdate = moment(givenDate);
  var todaysdate = moment();
  return eventdate.diff(todaysdate, 'days');
};

export const encodeString = (text) => {
  return Buffer.from(text, 'binary').toString('base64');
};

export const sortArrayByDate = (array, property) =>
  array.sort((a, b) => {
    return new Date(b[property]) - new Date(a[property]);
  });

export const dateStartedOrnot = (val) =>
  new Date(val) > new Date() ? false : true;

export const checkIsEmailValid = (email) => {
  const emailRegex =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
  return emailRegex.test(email);
};

// format number to Indian rupee
export const rupee = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  maximumSignificantDigits: 10,
});

// format number to US dollar
export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 10,
});

export const checkIsTokenExpired = (accessToken) => {
  try {
    const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));

    const expirationTime = decodedToken.exp;
    // new Date(1300000000);

    // Get the current time in seconds
    const currentTime = Math.floor(Date.now() / 1000);

    return expirationTime - currentTime < 10;
    return false;
  } catch (error) {
    console.error('Error decoding access token:', error);
    return null;
  }
};

export const decrpytUrl = (text) => {
  if (!text) {
    return null;
  }
  try {
    let textParts = text.split(':');
    let iv = Buffer.from(textParts.shift(), 'hex');
    let encryptedText = Buffer.from(textParts.join(':'), 'hex');
    let decipher = crypto.createDecipheriv(
      'aes-256-cbc',
      Buffer.from(config.decodeTeacherUrlKey),
      iv
    );
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  } catch (error) {
    console.error('Decrypt Value:', error);
  }
};

export const decrypt = (text) => {
  let textParts = text.split(":");
  let iv = Buffer.from(textParts.shift(), "hex");
  let encryptedText = Buffer.from(textParts.join(":"), "hex");
  let decipher = crypto.createDecipheriv("aes-256-cbc", Buffer.from(`${REACT_APP_DECODE_AWS_KEY}`), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};