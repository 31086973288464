import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import AWS from 'aws-sdk';
import { CloseSquareFilled } from '@ant-design/icons';
import './embedpdf.scss';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';
import config from '../../../../../../helpers/config';
import { decrypt } from '../../../../../../helpers/utility';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EmbeddedFile = ({
  playCurrentVideo,
  setCurrent,
  pdfData,
  isOlympiad,
  handleOlympiadPdfClose,
}) => {
  //   const [documentData, setDocumentData] = useState(null);
  const [documentURL, setDocumentURL] = useState('');
  const [isPDF, setIsPDF] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [loader, setLoader] = useState(true);
  const history = useHistory();

  // Function to fetch AWS credentials
  const fetchAwsCredentials = async () => {
    try {
      const response = await fetch(
        config?.apiBaseUrl + '/admin/readKeysFromEnv'
      ); // Replace with your API endpoint

      if (!response.status == 200) {
        throw new Error('Failed to fetch AWS credentials');
      }
      const { encryptedAWSAccessKey, encryptedAWSsecret } =
        await response.json();

      const accessKeyId = decrypt(encryptedAWSAccessKey);

      const secretAccessKey = decrypt(encryptedAWSsecret);

      AWS.config.update({
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        region: 'us-east-1',
      });
    } catch (error) {
      console.error('Error fetching AWS credentials:', error);
    }
  };

  const getSignedUrlHandler = async (fileName) => {
    try {
      const s3 = new AWS.S3();
      const params = {
        Bucket: 'tautmore-e-books',
        Key: fileName,
        Expires: 60 * 60,
      };

      const signedUrl = await s3.getSignedUrlPromise('getObject', params);

      return signedUrl;
    } catch (error) {
      console.log(error, 'from signed url');
    }
  };

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        setLoader(true);
        await fetchAwsCredentials(); // Fetch AWS keys before proceeding
        const bucketName = 'tautmore-e-books';
        const fileName = pdfData;

        const documentUrl = await getSignedUrlHandler(fileName);
        setDocumentURL(documentUrl);

        const fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
        setIsPDF(fileType === 'pdf');
        setLoader(false);

        window.open(documentUrl, '_blank');
      } catch (error) {
        console.error('Error fetching document:', error);
        setLoader(false);
      }
    };

    fetchDocument();
  }, [pdfData]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      <style>{`
        .pdf-page {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #ccc;
        }

        .pdf-page .react-pdf__Page__canvas {
          width: 100% !important;
          height: auto !important;
        }
      `}</style>
    </div>
  );
};

export default EmbeddedFile;
